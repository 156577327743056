import { getChats } from "@/requests";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Link, Outlet } from "react-router-dom";

import { useGlobalState } from "../../context/GlobalState";
import { SideBarProvider, useSideBar } from "../../context/SidebarState";
import withProviders from "../../context/withProviders";
import { classNames } from "../../lib/utils";
import {
  DiscordIcon,
  DropdownArrow,
  LinkedinIcon,
  NewChatIcon,
  RedditIcon,
  SideBarIcon,
  WhiteChatEtfLogo,
} from "../ui/icons";
import ChatItem from "./chat-item";
import { mapChatsToDiebarItems } from "./helpers";
import { DesktopSidebarSkeleton } from "./LoadingSkelaton";

const DarkSidebarWithHeader: React.FC = () => {
  return (
    <div className="flex h-screen transition-all duration-300">
      <SideBarProvider initialChats={[]}>
        {/* <MobileSidebarTrigger /> */}
        <DesktopSidebar />
        {/* <SidebarTrigger /> */}
        <div className="relative flex flex-1 flex-grow flex-col transition-all duration-300 lg:overflow-y-auto lg:overflow-x-hidden">
          <Outlet />
        </div>
      </SideBarProvider>
    </div>
  );
};

const DesktopSidebar: React.FC = () => {
  const { sidebarOpen } = useSideBar();

  return (
    <>
      <div
        className={`${sidebarOpen ? "inset-y-0 flex w-72 flex-col" : "w-0 flex-shrink"} flex-shrink-0 bg-primary-900 transition-all duration-300 lg:overflow-y-auto lg:overflow-x-hidden lg:shadow-lg lg:ring-1 lg:ring-white/10`}
      >
        <div
          className={`flex grow flex-col overflow-y-auto bg-primary-900 px-6 pb-4 ${!sidebarOpen ? "hidden" : ""}`}
        >
          <LogoAndIcon />
          <ChatHistory />
          <SidebarFooter />
        </div>
      </div>
    </>
  );
};

const ChatHistory: React.FC = () => {
  const {
    session: { access_token, user },
  } = useGlobalState();
  const { resetChatView, newChat } = useSideBar();

  const fetchChats = async ({ pageParam }: { pageParam: number }) =>
    getChats(user?.id, access_token, pageParam);

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: ["chats", { id: user?.id }],
    queryFn: fetchChats,
    initialPageParam: Math.floor(Date.now() / 1000),
    getNextPageParam: (lastPage) => lastPage.nextCursor,
  });

  if (status === "pending") return <DesktopSidebarSkeleton />;

  if (status === "error") {
    console.error(error);
    return null;
  }

  let navigationList = {
    name: "Chats",
    href: "/chats",
    current: true,
    children: mapChatsToDiebarItems(data.pages.flatMap((page) => page.data)),
  };

  if (newChat) {
    const doesTodayExist = navigationList.children.some(
      (chat) => chat.time_period === "Today",
    );

    if (doesTodayExist) {
      navigationList.children[0].chats.unshift(newChat);
    } else {
      navigationList.children.unshift({
        time_period: "Today",
        chats: [newChat],
      });
    }
  }

  return (
    <nav className="hide-scroll-bar flex max-h-full flex-1 flex-col overflow-y-auto overflow-x-hidden">
      <div className="flex flex-1 flex-col gap-y-7">
        {navigationList.children.length === 0 && (
          <div className="flex items-center justify-center">
            <p className="text-white">No chats yet</p>
          </div>
        )}
        <ul className="-mx-2 mt-1 space-y-1 px-2">
          {navigationList.children.map((item, index) => {
            if ("time_period" in item) {
              return (
                <li key={index}>
                  <div className="flex items-center justify-between gap-x-3">
                    <h3 className="my-3 pl-4 text-xs font-semibold capitalize tracking-wider text-[#658ea9]">
                      {item.time_period}
                    </h3>
                    {index === 0 && <NewChatButton />}
                  </div>
                  <ul className="mt-1 space-y-1">
                    {item.chats.map((chat, index) => (
                      <ChatItem
                        key={index}
                        chat={chat}
                        resetChatView={resetChatView}
                      />
                    ))}
                  </ul>
                </li>
              );
            }
          })}
          {hasNextPage && !isFetchingNextPage && (
            <li
              onClick={() => {
                fetchNextPage();
              }}
              className={classNames(
                "text-gray-400 hover:bg-primary-100 hover:text-white",
                "mt-2 block rounded-md px-3 text-sm leading-6 text-gray-400 hover:cursor-pointer",
              )}
              // Disable the Next Page button until we know a next page is available
            >
              {isFetchingNextPage ? (
                "Loading more..."
              ) : hasNextPage ? (
                <div className="flex items-center">
                  <DropdownArrow className="h-5 w-5" />
                  <span className="ml-2 text-xs">Load More</span>
                </div>
              ) : (
                "Nothing more to load"
              )}
            </li>
          )}
          {isFetchingNextPage && (
            <li className="flex items-center justify-center">
              <span className="loading loading-spinner loading-md text-gray-400"></span>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

const LogoAndIcon: React.FC = () => {
  const { sidebarOpen, setSidebarOpen, resetChatView } = useSideBar();

  return (
    <div className="flex h-16 shrink-0 items-center justify-between">
      <Link to="/dashboard">
        <div
          className="cursor-pointer text-3xl font-bold text-white"
          onClick={resetChatView}
        >
          <WhiteChatEtfLogo className="my-0 h-10 w-10/12 px-4" />
        </div>
      </Link>
      <div className="mt-1 pt-1">
        <button onClick={() => setSidebarOpen(!sidebarOpen)}>
          <SideBarIcon className="h-6 w-auto text-white" />
        </button>
      </div>
    </div>
  );
};

const SidebarFooter: React.FC = () => (
  <div>
    <hr className="mx-4 my-2 w-[90%] border-t-[1px] border-[#1C2C40] px-2" />
    <div className="flex items-center justify-center space-x-4">
      <Link
        to="https://www.reddit.com/r/ETFs/"
        target="_blank"
        className="flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-white hover:text-[#FF4500]"
      >
        <RedditIcon className="h-4 w-4" />
      </Link>
      <Link
        to="https://www.linkedin.com/company/chatetf/"
        target="_blank"
        className="flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-white hover:text-[#0077B5]"
      >
        <LinkedinIcon className="h-4 w-4" />
      </Link>
      {/* <Link
        to="https://discord.gg/u6RWjqty"
        target="_blank"
        className="flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-white hover:text-[#5865F2]"
      >
        <DiscordIcon className="h-4 w-4" />
      </Link> */}
    </div>
  </div>
);

const NewChatButton: React.FC = () => (
  <Link
    to={"/dashboard"}
    className={classNames(
      "text-gray-400 hover:text-white",
      "block rounded-md py-2 text-sm leading-6 text-gray-400",
    )}
  >
    <NewChatIcon
      className={classNames(
        "mr-[0.15rem] h-5 w-5 shrink-0 cursor-pointer text-white hover:scale-110 hover:transform",
      )}
      aria-hidden="true"
    />
  </Link>
);
export default withProviders(DarkSidebarWithHeader);
