import { useGlobalState } from "@/context/GlobalState";
import { cn } from "@/lib/utils";
import { getUserUnreadNotifications } from "@/requests/v1";
import { isBellNotificationType } from "@/types/customData";
import { useQuery } from "@tanstack/react-query";
import { ReactNode, useState } from "react";
import {
  CalendarIcon,
  GTrendIcon,
  InsightsReportsIcon,
  JobsIcon,
  LoadingCircle,
  NewsPaperIcon,
  NotificaitonIcon,
  XIcon,
} from "../ui/icons";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import Events from "./Events";
import GoogleTrendsWidget from "./GoogleTrends";
import News from "./News";
import Notifications from "./Notifications";
import TwitterFeed from "./TwitterFeed";

const RightSideBar: React.FC = () => {
  const [unreadData, setUnreadData] = useState<
    {
      icon:
        | "bell"
        | "news"
        | "calendar"
        | "insights"
        | "jobs"
        | "twitter"
        | "gtrend";
      unreadCount: number;
    }[]
  >([]);
  const {
    session: { access_token, user },
  } = useGlobalState();
  const { isPending, isError, error, data } = useQuery({
    queryKey: ["unread-notifications"],
    queryFn: async () => {
      const data = await getUserUnreadNotifications(access_token, user.id);
      // loop through the data and set the unread count
      // for each type of notification

      const newUnreadData = data.reduce(
        (acc, item) => {
          if (isBellNotificationType(item.category)) {
            // Find existing bell entry or create new one
            const bellIndex = acc.findIndex((x) => x.icon === "bell");
            if (bellIndex >= 0) {
              acc[bellIndex].unreadCount += item.unread_count;
            } else {
              acc.push({
                icon: "bell",
                unreadCount: item.unread_count,
              });
            }
          } else if (item.category === "news") {
            // Find existing news entry or create new one
            const newsIndex = acc.findIndex((x) => x.icon === "news");
            if (newsIndex >= 0) {
              acc[newsIndex].unreadCount += item.unread_count;
            } else {
              acc.push({
                icon: "news",
                unreadCount: item.unread_count,
              });
            }
          }
          return acc;
        },
        [] as typeof unreadData,
      );

      setUnreadData(newUnreadData);
      return data;
    },
    refetchInterval: 1000 * 15,
  });

  if (isPending)
    return (
      <div className="flex items-center justify-center pt-10">
        <LoadingCircle />
      </div>
    );

  return (
    <div className="fixed right-0 top-0 h-full bg-white p-4 shadow-lg">
      <div className="mt-32 flex flex-col items-center justify-center space-y-6">
        <SidebarItem
          icon={<NotificaitonIcon className="h-8 w-8 text-primary-500" />}
          widget={<Notifications />}
          hasNew={unreadData.some(
            (item) => item.icon === "bell" && item.unreadCount > 0,
          )}
          badgeClass="bottom-0 right-0 bg-blue-ice"
        />
        <SidebarItem
          icon={<NewsPaperIcon className="h-8 w-8 text-primary-500" />}
          widget={<News />}
          hasNew={unreadData.some(
            (item) => item.icon === "news" && item.unreadCount > 0,
          )}
          badgeClass="bottom-0 right-0 bg-blue-ice"
        />
        <SidebarItem
          icon={<CalendarIcon className="h-8 w-8 text-primary-500" />}
          widget={<Events />}
          hasNew={unreadData.some(
            (item) => item.icon === "calendar" && item.unreadCount > 0,
          )}
          badgeClass="bottom-0 right-0 bg-blue-ice"
          height="fit-content"
        />
        {/* <SideBarIcon>
          <InsightsReportsIcon className="h-8 w-8 text-primary-500" />
        </SideBarIcon> */}
        {/* <SideBarIcon>
          <JobsIcon className="h-8 w-8 text-primary-500" />
        </SideBarIcon> */}
        <SidebarItem
          icon={<XIcon className="h-6 w-6 text-primary-500" />}
          widget={<TwitterFeed />}
        />
        <SidebarItem
          icon={<GTrendIcon className="h-8 w-8 text-primary-500" />}
          widget={<GoogleTrendsWidget />}
        />
      </div>
    </div>
  );
};

type SidebarItemProps = {
  icon: ReactNode;
  widget: ReactNode;
  badgeClass?: string;
  hasNew?: boolean;
  height?: number | string;
};

const SidebarItem: React.FC<SidebarItemProps> = ({
  icon,
  widget,
  hasNew,
  badgeClass = "",
  height = 400,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="relative">
      <Popover onOpenChange={(open: boolean) => setIsOpen(open)}>
        <PopoverTrigger>
          <div
            className={cn("relative", {
              "rounded bg-blue-50": isOpen,
            })}
          >
            <SideBarIcon>{icon}</SideBarIcon>
            {hasNew && (
              <div
                className={cn([
                  "absolute h-[0.65rem] w-[0.65rem] rounded-full",
                  badgeClass,
                ])}
              />
            )}
          </div>
        </PopoverTrigger>
        <PopoverContent
          className={`top-[65px] w-[400px] overflow-y-scroll p-0`}
          side="left"
          sideOffset={20}
          alignOffset={20}
          style={{ height: height }}
        >
          {widget}
        </PopoverContent>
      </Popover>
    </div>
  );
};

const SideBarIcon: React.FC<{ children: ReactNode; onClick?: () => void }> = ({
  children,
  onClick,
}) => {
  return (
    <div className="cursor-pointer" onClick={onClick}>
      {children}
    </div>
  );
};

export default RightSideBar;
