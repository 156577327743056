import { PrismicRichText, usePrismicDocumentByID } from "@prismicio/react";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  format,
} from "date-fns";
import { IconClose, LoadingCircle, NotificaitonIcon } from "../ui/icons";
import { PopoverClose } from "../ui/popover";
import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNotifications } from "./useNotifications";
import { cn } from "@/lib/utils";

import { ensureAbsoluteUrl } from "./helpers";

type NewsSource = "etf_central" | "yahoo_finance" | "vettafi";

const News = () => {
  const [view, setView] = useState<"feed" | "settings">("feed");

  return (
    <div className="">
      <div className="flex items-center justify-between px-3 py-4">
        <h1 className="pl-2 text-xl font-bold">
          {view === "feed" ? "News" : "Settings"}
        </h1>
        <div className="flex items-center space-x-2">
          {/* <Settings
            className="hover:cursor-pointer"
            onClick={() =>
              setView((prev) => (prev === "feed" ? "settings" : "feed"))
            }
            size={24}
          /> */}
          <PopoverClose className="PopoverClose" aria-label="Close">
            <IconClose className="h-6 w-6" />
          </PopoverClose>
        </div>
      </div>
      <WidgetContent view={view} />
    </div>
  );
};

const DateStringFormatter = (date: string): string => {
  try {
    const parsedDate = new Date(date);
    const now = new Date();
    const diffInMinutes = differenceInMinutes(now, parsedDate);
    const diffInHours = differenceInHours(now, parsedDate);
    const diffInDays = differenceInDays(now, parsedDate);
    const diffInMonths = differenceInMonths(now, parsedDate);

    if (diffInMinutes < 60) {
      return `${diffInMinutes} mins`;
    } else if (diffInHours < 24) {
      return `${diffInHours}h`;
    } else if (diffInDays < 30) {
      return `${diffInDays}d`;
    } else if (diffInMonths < 12) {
      return `${diffInMonths}mo`;
    } else {
      return format(parsedDate, "MMM d, yyyy");
    }
  } catch (error) {
    console.error("Error formatting date:", error);
    return "";
  }
};

const NotificationsItem = ({
  id,
  is_read,
  onMount,
}: {
  id: string;
  is_read: boolean;
  onMount: (id: string, isValid: boolean) => void;
}) => {
  const [document] = usePrismicDocumentByID(id);
  const hasNotified = useRef(false);

  useEffect(() => {
    if (!hasNotified.current) {
      console.log(
        `Notifying mount for item ${id}, document exists: ${!!document}`,
      );
      onMount(id, !!document);
      hasNotified.current = true;
    }
  }, [document, id, onMount]);

  if (!document) {
    return null;
  }

  const type = document.data.source as NewsSource;
  const publishDate = new Date(document.first_publication_date);

  var tags = document.tags;

  const url = ensureAbsoluteUrl(document.data.link.url);

  return (
    <div
      className={cn("border-b border-gray-100", !is_read && "bg-blue-lightIce")}
    >
      <div className="grid grid-cols-[auto_1fr_auto] items-start gap-x-4 p-4">
        <div className="pt-1">
          {type === "etf_central" ? (
            <img
              src={"/assets/etf-central.png"}
              alt={"ETF Central"}
              className="h-6 w-6 rounded-md"
            />
          ) : type === "yahoo_finance" ? (
            <img
              src={"/assets/yahoo.png"}
              alt={"Yahoo Finance"}
              className="h-6 w-6 rounded-md"
            />
          ) : type === "vettafi" ? (
            <img
              src={"/assets/vettafi.jpg"}
              alt={"Vettafi"}
              className="h-6 w-6 rounded-md"
            />
          ) : (
            <NotificaitonIcon className="h-7 w-7 text-primary-500" />
          )}
        </div>
        <div>
          <div className="text-base font-normal">
            <a href={url} target="_blank">
              <PrismicRichText field={document.data.notification_content} />
            </a>
          </div>
          <div className="my-2 text-xs text-gray-500">
            {type === "etf_central"
              ? "ETF Central"
              : type === "yahoo_finance"
                ? "Yahoo Finance"
                : type === "vettafi"
                  ? "Vettafi"
                  : ""}{" "}
          </div>
          <div className="flex items-center space-x-2 text-xs text-gray-500">
            {tags.map((tag) => (
              <span
                key={tag}
                className="rounded-md bg-gray-100 px-2 py-1 text-xs text-gray-500"
              >
                {tag}
              </span>
            ))}
          </div>
        </div>
        <div className="text-xs text-gray-500">
          {DateStringFormatter(publishDate.toISOString())}
        </div>
      </div>
    </div>
  );
};

const NotificationsList = () => {
  const { isPending, isError, data } = useNotifications();
  const [validItems, setValidItems] = useState<Set<string>>(new Set());
  const [allItemsChecked, setAllItemsChecked] = useState(false);
  const mountedItemsCount = useRef(0);

  const handleItemMount = useCallback(
    (id: string, isValid: boolean) => {
      console.log(
        `Item ${id} mounted, isValid: ${isValid}, current count: ${mountedItemsCount.current}`,
      );

      setValidItems((prev) => {
        const newSet = new Set(prev);
        if (isValid) {
          newSet.add(id);
        } else {
          newSet.delete(id);
        }
        return newSet;
      });

      mountedItemsCount.current += 1;

      if (data) {
        const newsDataLength = data.filter(
          (item) => item.type === "news",
        ).length;
        console.log(
          `Mounted: ${mountedItemsCount.current}, Total needed: ${newsDataLength}`,
        );

        if (mountedItemsCount.current >= newsDataLength) {
          console.log("All items checked, setting allItemsChecked to true");
          setAllItemsChecked(true);
        }
      }
    },
    [data],
  );

  // Reset when data changes
  useEffect(() => {
    console.log("Data changed, resetting counts");
    setValidItems(new Set());
    setAllItemsChecked(false);
    mountedItemsCount.current = 0;
  }, [data]);

  const notificationData = data?.filter((item) => item.type === "news") ?? [];

  console.log(
    `Current state - Checked: ${allItemsChecked}, Valid items: ${validItems.size}, Total items: ${notificationData.length}`,
  );

  if (isPending) {
    return (
      <div className="flex items-center justify-center py-10">
        <LoadingCircle />
      </div>
    );
  }

  if (isError) return null;
  if (!data || notificationData.length === 0) {
    return (
      <div className="flex items-center justify-center py-10">
        <p className="text-black">Nothing here yet...</p>
      </div>
    );
  }

  // Still loading if not all items have been checked
  if (!allItemsChecked) {
    return (
      <div>
        {/* Render items without hiding them to better track mounting */}
        {notificationData.map((notification) => (
          <NotificationsItem
            key={notification.doc_id}
            id={notification.doc_id}
            is_read={notification.is_read}
            onMount={handleItemMount}
          />
        ))}
        <div className="flex items-center justify-center py-4">
          <p className="text-gray-500">All caught up!</p>
        </div>
      </div>
    );
  }

  // Show empty state if no valid items after all checks
  if (validItems.size === 0) {
    return (
      <div className="flex items-center justify-center pt-10">
        <p className="text-black">Nothing here yet...</p>
      </div>
    );
  }

  // Only render valid items
  return (
    <div>
      {notificationData
        .filter((notification) => validItems.has(notification.doc_id))
        .map((notification) => (
          <NotificationsItem
            key={notification.doc_id}
            id={notification.doc_id}
            is_read={notification.is_read}
            onMount={handleItemMount}
          />
        ))}
    </div>
  );
};

export default News;

const WidgetContent = ({ view }: { view: "feed" | "settings" }) => {
  return (
    <AnimatePresence mode="wait">
      {view === "feed" ? (
        <motion.div
          key="feed"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 20 }}
          transition={{ duration: 0.2 }}
        >
          <NotificationsList />
        </motion.div>
      ) : view === "settings" ? (
        <motion.div
          key="settings"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 20 }}
          transition={{ duration: 0.2 }}
        >
          <div> Settings in Progress.. </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};
