import { PrismicRichText, usePrismicDocumentByID } from "@prismicio/react";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  format,
} from "date-fns";
import {
  ClosureIcon,
  EcosystemIcon,
  FilingIcon,
  FundUpdateIcon,
  IconClose,
  LaunchIcon,
  ListingsIcon,
  LoadingCircle,
  NotificaitonIcon,
  PeopleMovesIcon,
  RegulatoryIcon,
} from "../ui/icons";
import { PopoverClose } from "../ui/popover";
import { AnimatePresence, motion } from "framer-motion";
import { useRef, useState } from "react";
import { cn } from "@/lib/utils";
import { useNotifications } from "./useNotifications";
import { ensureAbsoluteUrl } from "./helpers";

type NotificationType =
  | "Launches"
  | "Closures"
  | "Filings"
  | "Fund Updates"
  | "Regulatory Updates"
  | "People Moves"
  | "Ecosystem"
  | "Listings";

const Notifications = () => {
  const [view, setView] = useState<"feed" | "settings">("feed");

  return (
    <div className="">
      <div className="flex items-center justify-between px-3 py-4">
        <h1 className="pl-2 text-xl font-bold">
          {view === "feed" ? "Notifications" : "Settings"}
        </h1>
        <div className="flex items-center space-x-2">
          {/* <Settings
            className="hover:cursor-pointer"
            onClick={() =>
              setView((prev) => (prev === "feed" ? "settings" : "feed"))
            }
            size={24}
          /> */}
          <PopoverClose className="PopoverClose" aria-label="Close">
            <IconClose className="h-6 w-6" />
          </PopoverClose>
        </div>
      </div>
      <WidgetContent view={view} />
    </div>
  );
};

const DateStringFormatter = (date: string): string => {
  try {
    const parsedDate = new Date(date);
    const now = new Date();
    const diffInMinutes = differenceInMinutes(now, parsedDate);
    const diffInHours = differenceInHours(now, parsedDate);
    const diffInDays = differenceInDays(now, parsedDate);
    const diffInMonths = differenceInMonths(now, parsedDate);

    if (diffInMinutes < 60) {
      return `${diffInMinutes} mins`;
    } else if (diffInHours < 24) {
      return `${diffInHours}h`;
    } else if (diffInDays < 30) {
      return `${diffInDays}d`;
    } else if (diffInMonths < 12) {
      return `${diffInMonths}mo`;
    } else {
      return format(parsedDate, "MMM d, yyyy");
    }
  } catch (error) {
    console.error("Error formatting date:", error);
    return "";
  }
};

const NotificationsItem = ({
  id,
  is_read,
}: {
  id: string;
  is_read: boolean;
}) => {
  const [document] = usePrismicDocumentByID(id);
  const documentStatus = useRef<boolean | null>(null);

  if (!document) {
    return null;
  }

  const type = document.data.type as NotificationType;
  const publishDate = new Date(document.first_publication_date);
  const url = ensureAbsoluteUrl(document.data.link.url);

  return (
    <div
      className={cn("border-b border-gray-100", !is_read && "bg-blue-lightIce")}
    >
      <div className="mt-3 grid grid-cols-[auto_1fr_auto] items-center gap-x-4 p-4">
        {(() => {
          switch (type) {
            case "Launches":
              return <LaunchIcon className="h-7 w-7 text-primary-500" />;
            case "Closures":
              return <ClosureIcon className="h-7 w-7 text-primary-500" />;
            case "Filings":
              return (
                <FilingIcon className="h-7 w-7 self-start text-primary-500" />
              );
            case "Fund Updates":
              return <FundUpdateIcon className="h-7 w-7 text-primary-500" />;
            case "Regulatory Updates":
              return <RegulatoryIcon className="h-7 w-7 text-primary-500" />;
            case "People Moves":
              return (
                <PeopleMovesIcon className="h-7 w-7 self-start text-primary-500" />
              );
            case "Ecosystem":
              return <EcosystemIcon className="h-7 w-7 text-primary-500" />;
            case "Listings":
              return <ListingsIcon className="h-7 w-7 text-primary-500" />;
            default:
              return <NotificaitonIcon className="h-7 w-7 text-primary-500" />;
          }
        })()}
        <div>
          <div className="text-sm font-semibold">{type}</div>
          <div className="text-sm">
            {url ? (
              <a href={url} target="_blank">
                <PrismicRichText field={document.data.notification_content} />
              </a>
            ) : (
              <PrismicRichText field={document.data.notification_content} />
            )}
          </div>
        </div>
        <div className="text-xs text-gray-500">
          {DateStringFormatter(publishDate.toISOString())}
        </div>
      </div>
    </div>
  );
};

const NotificationsList = () => {
  const { isPending, isError, data } = useNotifications();

  if (isPending) {
    return (
      <div className="flex items-center justify-center pt-10">
        <LoadingCircle />
      </div>
    );
  }

  if (isError) return null;
  if (!data) {
    return (
      <div className="flex items-center justify-center py-10">
        <p className="text-black">Nothing here yet...</p>
      </div>
    );
  }

  const notificationData = data.filter((item) => item.type !== "news").sort((a, b) => {
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  })

  // Only render valid items
  return (
    <div>
      {notificationData.map((notification) => (
        <NotificationsItem
          key={notification.doc_id}
          id={notification.doc_id}
          is_read={notification.is_read}
        />
      ))}
    </div>
  );
};

export default Notifications;

const WidgetContent = ({ view }: { view: "feed" | "settings" }) => {
  return (
    <AnimatePresence mode="wait">
      {view === "feed" ? (
        <motion.div
          key="feed"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 20 }}
          transition={{ duration: 0.2 }}
        >
          <NotificationsList />
        </motion.div>
      ) : view === "settings" ? (
        <motion.div
          key="settings"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 20 }}
          transition={{ duration: 0.2 }}
        >
          <div> Settings in Progress.. </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};
