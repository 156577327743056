import { useRef } from "react";

interface GoogleTrendsConfig {
  keyword?: string;
  geo?: string;
  timeRange?: string;
  category?: number;
}

const GoogleTrendsWidget: React.FC<GoogleTrendsConfig> = ({
  keyword = "/m/02mxjp",
  geo = "US",
  timeRange = "now 7-d",
  category = 0,
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const buildTrendsURL = () => {
    const baseURL =
      "https://trends.google.com:443/trends/embed/explore/RELATED_QUERIES";

    const comparisonItem = [
      {
        keyword,
        geo,
        time: timeRange,
      },
    ];

    const requestParams = {
      comparisonItem,
      category,
      property: "",
      filter: "RISING_TOP",
    };

    const urlParams = new URLSearchParams({
      req: JSON.stringify(requestParams),
      tz: "-120",
      eq: `date=${encodeURIComponent(timeRange)}&geo=${geo}&q=${encodeURIComponent(keyword)}&hl=en`,
    });

    return `${baseURL}?${urlParams.toString()}`;
  };

  return (
    <div className="rounded-lg bg-transparent p-4">
      <iframe
        ref={iframeRef}
        src={buildTrendsURL()}
        className="h-[350px] w-full"
        title="Google Trends Widget"
        frameBorder="0"
      />
    </div>
  );
};

export default GoogleTrendsWidget;
