import { useRef } from "react";

interface TwitterFeedConfig {
  embedId?: string;
  height?: string;
  width?: string;
}

const TwitterFeed: React.FC<TwitterFeedConfig> = ({
  embedId = "25501165",
  height = "350px",
  width = "100%",
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const buildTwitterURL = () => {
    return `https://widgets.sociablekit.com/twitter-list/iframe/${embedId}`;
  };

  return (
    <div className="rounded-lg bg-transparent">
      <iframe
        ref={iframeRef}
        src={buildTwitterURL()}
        className="h-[350px] w-full"
        title="Twitter Feed Widget"
        frameBorder="0"
      />
    </div>
  );
};

export default TwitterFeed;
