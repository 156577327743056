import { Message } from "ai";
import { Tables } from "./database.types";
// import { Point } from "chart.js";
//
export type BellNotificationType =
  | "Launches"
  | "Closures"
  | "Filings"
  | "Fund Updates"
  | "Regulatory Updates"
  | "People Moves"
  | "Listings"
  | "notification"

export type News = "news"

export type NotificationType = BellNotificationType | News;

const bellNotificationTypes = [
  "Launches",
  "Closures",
  "Filings",
  "Fund Updates",
  "Regulatory Updates",
  "People Moves",
  "Listings",
  "notification"
] as const;

export function isBellNotificationType(value: string): value is BellNotificationType {
  return bellNotificationTypes.includes(value as BellNotificationType);
}


export interface UserNotification {
  id: string;
  type: string;
  doc_id: string;
  created_at: string;
  is_read: boolean;
  source: string;
}

export interface UserUnreadNotifications {
  category: NotificationType;
  unread_count: number;
}

export interface RelatedDocsItem {
  id: string
  document_name: string
  document_link: string
  metadata: {
    size: string
    mimetype: string
  }
}

export interface ICompariosonETfs { etf1: number | string; etf2: number | string; }
export interface ChartCard {
  title: string;
  chartData: {
    chartType: string;
    index: string;
    categories: string[];
    color?:
    | "slate"
    | "gray"
    | "zinc"
    | "neutral"
    | "stone"
    | "red"
    | "orange"
    | "amber"
    | "yellow"
    | "lime"
    | "green"
    | "emerald"
    | "teal"
    | "cyan"
    | "sky"
    | "blue"
    | "indigo"
    | "violet"
    | "purple"
    | "fuchsia"
    | "pink"
    | "rose";
    //   data: Point[];
    data: any[];
    yAxisSymbol?: string;
    delta?: {
      valueChange: number;
      percentageChange: number;
      text: string;
    };
  };
}

export interface GraphCard {
  labels: string;
  datasets: Array<{
    label: string;
    data: number[][];
    borderColor: string;
    backgroundColor: string;
  }>;
  type: "%" | "$" | "ytd-not-available";
}

export interface PerformanceCard {
  etf_exchange: string;
  etfFullName: string;
  graphCards: GraphCard[];
  priceData: GraphCard[];
  priceGraphOptions: any;
  activeTab: string;
  timePeriodCount: number;
  etfName: string;
}

export interface IBarCard {
  type: "bar";
  name: string;
  data: Array<any>;
  yAxisIndex: number;
}

export interface IPieChartCard {
  chartType: "pie";
  option: object;
  etf_full_name: string;
  etf_code: string;
  etf_exchange: string;
  etf_avg_market_cap_string: string;
}

export interface IExcelCard {
  excelData: any;
  fileName: string;
}

export interface ILabelData {
  label: string;
  value: any;
}

interface PrismicFundData {
  fund_ticker: string;
  desccription: string;
  investment_risks: string;
  is_diversified: boolean;
  related_docs: RelatedDoc[];
}

interface RelatedDoc {
  related_document: RelatedDocument;
}

interface RelatedDocument {
  link_type: string;
  key: string;
  kind: string;
  id: string;
  url: string;
  name: string;
  size: string;
}

export interface TiCardData {
  aum: number;
  nav: number;
  expense_ratio: number;
  fund_label: string;
  fund_structure: string;
  generic_index: string;
  inception_date: string;
  investment_objective: string;
  issuer_website: string;
  main_ticker: string;
  management_style: string;
  nav_one_day_change: number;
  provider: string;
  as_of_date: string;
  prismic: PrismicFundData | null;
  fund_managers: IIFundManager[];
}

export interface ISimilarEtfData {
  isin: string;
  main_ticker: string;
  share_id: number;
  etf_name: string;
  provider: string;
  zone: string;
  main_exchange: string;
  expense_ratio: number;
  jurisdiction: string;
  fund_structure: string;
  creation_date: string;
  dividend_policy: string;
  actively_managed: boolean;
  generic_index: string | null;
  universes: string | null;
  aum_usd: number;
  basic_materials_weight: number | null;
  consumer_cyclicals_weight: number | null;
  financial_services_weight: number | null;
  real_estate_weight: number | null;
  communication_services_weight: number | null;
  energy_weight: number | null;
  industrials_weight: number | null;
  technology_weight: number | null;
  consumer_defensive_weight: number | null;
  healthcare_weight: number | null;
  utilities_weight: number | null;
  one_yr_volatility: number;
  three_yr_volatility: number;
  returns_ytd: number;
  returns_1y: number;
  returns_3y: number;
  returns_5y: number;
  returns_10y: number;
  provider_url: string;
  timestamp: number;
  gmtoffset: number;
  open: number;
  high: number;
  low: number;
  close_price: number;
  volume: number;
  change: number;
  change_p: number;
  code: string;
  name: string;
}

export interface RelatedDocsItem {
  id: string
  document_name: string
  document_link: string
  metadata: {
    size: string
    mimetype: string
  }
}

export interface ITVChartDataItem {
  time: string;
  value: number;
  color?: string;
}

export interface ITimeSeriesData {
  id?: string
  date: string
  prev_date?: string
  nav: number
  dividend: number | null
  aum: number
  flow: number
  net_return: number
}

export interface INewsData {
  source: string;
  title: string;
  link: string;
  excerpt: string;
  publisherFaviconUrl: string;
  publishedAt: string;
  image: {
    url: string;
    alt: string | null;
    dimensions: {
      width: number;
      height: number;
    };
  }
  author: {
    name: string;
    image: {
      url: string;
      alt: string;
      dimensions: {
        width: number;
        height: number;
      };
    }
  }
}

export interface ICompareETFData {
  etf_info: { etf_share_id: number; etf_ticker: string }[];
  holdingsData: IHoldingsData;
}

export interface IAnalyzeETfData {
  etf_provider_url: string;
  // similar_etfs: ISimilarEtfData[];
  related_docs: RelatedDocsItem[] | null;
  etf_in_question: string;
  graphCards: {}[];
  priceData: any[];
  priceGraphOptions: {};
  etf_exchange: string;
  etf_info: string;
  etfFullName: string;
  activeTab: string;
  timePeriodCount: number;
  etfName: string;
  top_10_holdings_pie_chart: {};
  sector_weights_bar_chart: {};
  market_cap_bar_chart: {};
  world_regions_bar_chart: {};
  isBondETF: boolean;
  etf_label_data: {
    etfID: string;
    data: {
      label: string;
      value: string | number | boolean | null;
    }[];
  };
  type: string;
  id: string;
  newsData?: INewsData[];
  reportCard: {
    price: number;
    price_change: number;
    price_change_percent: number;
    price_timestamp: number;
    etf_general_info: string;
    charts: {
      alltime: {
        priceData: ITVChartDataItem[];
        performanceData: ITVChartDataItem[];
        volumeData: ITVChartDataItem[];
      },
      aumData: {
        rawData: ITimeSeriesData[];
        filtered: ITVChartDataItem[];
      }
    },
    holdings: {
      [key: string]: {
        Code: string;
        Name: string;
        Sector: string;
        Industry: string;
        Region: string;
        Country: string;
        Exchange: string;
        "Assets_%": number;
      }
    }
  },
  ti_data: TiData;
}
export interface IETFComparisonData {
  etfID: string;
  fund_ids: number[];
  data: ILabelData[];
}
export type StateChartCards = Array<PerformanceCard>;

export type MessageWithCustomData = Message & {
  customData?: {
    [key: string]: any;
  };
};

export interface RelatedDocsItem {
  id: string;
  document_name: string;
  document_link: string;
  metadata: {
    size: string;
    mimetype: string;
  };
}

export interface CRRelatedDocs {
  type: "related_docs";
  related_docs: RelatedDocsItem[];
}

export interface IEODHoldingData {
  [key: string]: {
    "Name": string,
    "Code": string,
    "Exchange": string,
    "Sector": string,
    "Industry": string,
    "Country": string,
    "Region": string,
    "Assets_%": number
  }
}

export interface IHoldingsOverlapTabularData {
  company_name: string;
  ticker: string;
  sector: string;
  industry: string;
  country: string;
  region: string;
  etf_1_weight: number;
  etf_2_weight: number;
  overlap: number;
}

export interface IHoldingsData {
  shared_holdings_count: number;
  holdings_overlap_percentage: number;
  precentage_etf2_in_etf1: number;
  precentage_etf1_in_etf2: number;
  holdings_overlap_tabular_data: IHoldingsOverlapTabularData[];
}

export interface IChartDataItem {
  name: string;
  value: string;
}

export interface IChartSeriesDataItem {
  value: string;
  label: {
    show: boolean;
    formatter?: string;
    position?: string;
  };
}

export type SimilarEtfs = Tables<"etf_info"> & { provider_url: string };


export interface TiData {
  isin: string;
  zone: string;
  currency: string;
  provider: string;
  esg_grade: string;
  class_size: string[]
  fund_id: number;
  share_id: number;
  fund_label: string;
  "sector.date": string;
  "sector.weights": {
    key: string;
    value: number;
  }[]
  "country.weights": {
    key: string;
    value: number;
  }[]
  share_class: string;
  "country.date": string;
  index_ticker: string | null;
  jurisdiction: string;
  expense_ratio: number;
  generic_index: string | null;
  listing_place: string;
  tracking_since: string;
  actively_managed: boolean;
  dividend_policy: string;
  inception_date: string;
  class_asset_class: string[];
  class_sector: string[];
  class_bond_type: string[];
  class_commodity: string[];
  class_stock_type: string[];
  class_issuer_type: string[];
  benchmark: string;
  main_ticker: string;
  share_label: string;
  delisting_date: string | null;
  fund_structure: string;
  index_currency: string | null;
  index_provider: string | null;
  "one_day.aum_eur": number;
  "week_to_date.to": string;
  "year_to_date.to": string;
  "month_to_date.to": string;
  "one_day.flows_eur": number;
  "rating_peer_group": string;
  "replication_model": string;
  "week_to_date.from": string;
  "year_to_date.from": string;
  "month_to_date.from": string;
  "one_day.net_return": number;
  "one_week.flows_eur": number;
  "replication_method": string;
  "class_bond_maturity": any[];
  "class_credit_rating": any[];
  "one_week.net_return": number;
  "one_year_to_date.to": string;
  "class_cryptocurrency": any[];
  "issue_flag_base_data": boolean;
  "main_ticker_currency": string;
  "one_day.gross_return": number;
  "factor_sensitivity_to": string;
  "index_dividend_policy": string;
  "one_week.gross_return": number;
  "one_year_to_date.from": string;
  "rating_hurst_exponent": number;
  "three_year_to_date.to": string;
  "class_inverse_leverage": any[];
  "class_sector_exclusion": any[];
  "factor_sensitivity_r_2": number;
  "one_day.net_return_eur": number;
  "rating_hurst_threshold": number;
  "three_month_to_date.to": string;
  "week_to_date.flows_eur": number;
  "year_to_date.flows_eur": number;
  "factor_sensitivity_from": string;
  "factor_sensitivity_size": number;
  "month_to_date.flows_eur": number;
  "one_week.net_return_eur": number;
  "three_year_to_date.from": string;
  "week_to_date.net_return": number;
  "year_to_date.net_return": number;
  "class_geographical_focus": string[];
  "factor_sensitivity_value": number;
  "month_to_date.net_return": number;
  "three_month_to_date.from": string;
  "class_commodity_exclusion": any[];
  "factor_sensitivity_market": number;
  "factor_sensitivity_region": string;
  "one_week.number_of_points": number;
  "week_to_date.gross_return": number;
  "year_to_date.gross_return": number;
  "class_multi_asset_strategy": any[];
  "month_to_date.gross_return": number;
  "one_week.net_excess_return": number;
  "one_year_to_date.flows_eur": number;
  "factor_sensitivity_momentum": number;
  "issue_flag_replication_data": boolean;
  "one_year_to_date.net_return": number;
  "rating_peer_group_median_td": number;
  "rating_peer_group_median_te": number;
  "week_to_date.net_return_eur": number;
  "year_to_date.net_return_eur": number;
  "class_bonds_denomication_ccy": any[];
  "class_geographical_exclusion": any[];
  "month_to_date.net_return_eur": number;
  "one_week.gross_excess_return": number;
  "three_year_to_date.flows_eur": number;
  "factor_sensitivity_investment": number;
  "factor_sensitivity_volatility": number;
  "one_year_to_date.gross_return": number;
  "three_month_to_date.flows_eur": number;
  "three_year_to_date.net_return": number;
  "week_to_date.number_of_points": number;
  "year_to_date.number_of_points": number;
  "month_to_date.number_of_points": number;
  "three_month_to_date.net_return": number;
  "week_to_date.net_excess_return": number;
  "year_to_date.net_excess_return": number;
  "month_to_date.net_excess_return": number;
  "one_year_to_date.net_return_eur": number;
  "three_year_to_date.gross_return": number;
  "factor_sensitivity_profitability": number;
  "three_month_to_date.gross_return": number;
  "week_to_date.gross_excess_return": number;
  "year_to_date.gross_excess_return": number;
  "month_to_date.gross_excess_return": number;
  "one_week.average_daily_volume_eur": number;
  "one_year_to_date.number_of_points": number;
  "rating_peer_group_median_kurtosis": number;
  "three_year_to_date.net_return_eur": number;
  "one_year_to_date.net_excess_return": number;
  "three_month_to_date.net_return_eur": number;
  "three_year_to_date.number_of_points": number;
  "one_year_to_date.gross_excess_return": number;
  "three_month_to_date.number_of_points": number;
  "three_year_to_date.net_excess_return": number;
  "three_month_to_date.net_excess_return": number;
  "week_to_date.average_daily_volume_eur": number;
  "year_to_date.average_daily_volume_eur": number;
  "class_bonds_denomication_ccy_exclusion": any[];
  "month_to_date.average_daily_volume_eur": number;
  "one_week.annualized_net_tracking_error": number;
  "three_year_to_date.gross_excess_return": number;
  "three_month_to_date.gross_excess_return": number;
  "one_week.annualized_gross_tracking_error": number;
  "one_year_to_date.average_daily_volume_eur": number;
  "week_to_date.annualized_net_tracking_error": number;
  "year_to_date.annualized_net_tracking_error": number;
  "month_to_date.annualized_net_tracking_error": number;
  "three_year_to_date.average_daily_volume_eur": number;
  "three_month_to_date.average_daily_volume_eur": number;
  "week_to_date.annualized_gross_tracking_error": number;
  "year_to_date.annualized_gross_tracking_error": number;
  "month_to_date.annualized_gross_tracking_error": number;
  "one_year_to_date.annualized_net_tracking_error": number;
  "one_year_to_date.annualized_gross_tracking_error": number;
  "three_year_to_date.annualized_net_tracking_error": number;
  "three_month_to_date.annualized_net_tracking_error": number;
  "three_year_to_date.annualized_gross_tracking_error": number;
  "three_month_to_date.annualized_gross_tracking_error": number;
}

export type IETFTiData = TiData & {
  aum: number | null;
  nav: number | null;
  nav_date: string | null;
  holdings: IEODHoldingData;
  holdings_count: number;
  nav_one_day_change: number | null;
  historical_dividend_data: {
    total_trailing_12_month_yield: number;
    total_trailing_12_month_yield_per_share: number;
    dividend_data: number[];
    dividend_yield_data: number[];
  } | null;
  dividend_only_data: ITimeSeriesData[];
  returns_data: { date: string; net_return: number }[];
  total_trailing_12_month_yield: number;
  distribution_frequency: string;
  last_distribution: number;
  last_distribution_date: string;
  investment_objective: string;
  provider_url: string | null;
  price: number;
  price_change: number;
  price_change_percent: number;
  price_timestamp: number;
  trailing_returns_data: {
    trailing_returns: {
      YTD_returns: number
      one_month_returns: number
      three_month_returns: number
      six_month_returns: number
      one_year_returns: number
      three_year_returns: number
      five_year_returns: number
    },
    total_returns: {
      this_year_returns: number
      last_year_returns: number
      two_years_ago_returns: number
      three_years_ago_returns: number
      four_years_ago_returns: number
      five_years_ago_returns: number
      six_years_ago_returns: number
    },
  };
  market_cap: {
    Mega: string;
    Big: string;
    Medium: string;
    Smaall: string;
    Micro: string;
  } | null;
}

export type IDividendData = {
  current_year_dividend_total: number;
  last_year_dividend_total: number;
  two_years_ago_dividend_total: number;
  three_years_ago_dividend_total: number;
  four_years_ago_dividend_total: number;
}

export type IDividendYieldData = {
  total_trailing_12_month_yield: number;
  current_year_yield: number;
  last_year_yield: number;
  two_years_ago_yield: number;
  three_years_ago_yield: number;
  four_years_ago_yield: number;
}

export type IDividendMetrics = {
  total_trailing_12_month_yield_per_share: number;
  dividend_data: IDividendData;
  dividend_yield_data: IDividendYieldData
  distribution_frequency: string;
  last_distribution_date: string;
  last_distribution_amount: number;
}

export type ITrailingReturnsData = {
  trailing_returns: {
    YTD_returns: number
    one_month_returns: number
    three_month_returns: number
    six_month_returns: number
    one_year_returns: number
    three_year_returns: number
    five_year_returns: number
  },
  total_returns: {
    this_year_returns: number
    last_year_returns: number
    two_years_ago_returns: number
    three_years_ago_returns: number
    four_years_ago_returns: number
    five_years_ago_returns: number
    six_years_ago_returns: number
  },
}

export type IMarketCap = {
  Mega: string;
  Big: string;
  Medium: string;
  Smaall: string;
  Micro: string;
}

export type IETFV1FactSheetData = {
  aum: number;
  nav: number;
  nav_date: string;
  nav_one_day_change: number;
  issuer_website: string;
  holdings: IEODHoldingData;
  holdings_count: number;
  market_cap: IMarketCap;
  ti_data: TiData;
  description: string;
  price: number;
  price_change: number;
  price_change_percent: number;
  price_timestamp: number;
  chart_metrics: {
    performance_metrics: ChartData;
    volume_metrics: ChartData;
    price_metrics: ChartData;
  }
  dividend_metrics: IDividendMetrics;
  trailing_returns_data: ITrailingReturnsData;
  returns_data: { date: string; net_return: number }[];
  dividend_only_data: ITimeSeriesData[];
};

interface Flows {
  flows_1m: number;
  flows_3m: number;
  flows_1y: number;
  flows_ytd: number;
  flows_3y: number;
  flows_1w: number;
}

export type IETFFlowsPerformanceData = {
  trailing_returns_data: ITrailingReturnsData;
  etf_central_metrics: Flows;
}

export type ChartData = {
  five_days: ITVChartDataItem[];
  one_month: ITVChartDataItem[];
  three_months: ITVChartDataItem[];
  six_months: ITVChartDataItem[];
  one_year: ITVChartDataItem[];
  five_years: ITVChartDataItem[];
  ten_years: ITVChartDataItem[];
  YTD: ITVChartDataItem[];
  MTD: ITVChartDataItem[];
  QTD: ITVChartDataItem[];
  alltime: ITVChartDataItem[];
}

export type IPerformanceMetrics = {
  performance_metrics: ChartData;
  volume_metrics: ChartData;
  price_metrics: ChartData;
  aum_metrics: ChartData;
  flows_metrics: ChartData;
  cumulative_flows_metrics: ChartData;
  updated_date: string;
}

export type IFactsheetHeaderData = {
  title: string;
  main_ticker: string;
  main_exchange: string;
  issuer_website: string;
  current_price: number;
  price_change: number;
  price_change_percent: number;
  price_change_timestamp: string;
}

export type IFactSheetMoreDetails = {
  asset_classes: string[];
  actively_managed: boolean;
  nav_one_day_change: number;
  isin: string;
  expense_ratio: number;
  inception_date: string;
  aum: number;
  generic_index: string;
  nav: number;
  last_updated: string;
}

export type IFactSheetHoldingsAndSectors = {
  holdings: IEODHoldingData;
  holdings_count: number;
  market_cap: IMarketCap;
  sector_weights: {
    key: string;
    value: number;
  }[]
  sector_date: string;
  country_weights: {
    key: string;
    value: number;
  }[]
}

export type IFactSheetSectors = {
  sector_weights: {
    key: string;
    value: number;
  }[]
  sector_date: string;
  country_weights: {
    key: string;
    value: number;
  }[]
}

export interface IDrawdownDataResponse {
  drawdown: ChartData
}

export interface IVolatilityDataResponse {
  volatility: ChartData
}

export interface IVDMetricTimes {
  threeYears: number
  oneYear: number
  threeMonths: number
}

export interface IVolatilityDrawdownData {
  drawdown: {
    maxDrawdown: IVDMetricTimes
    maxDrawdownDuration: IVDMetricTimes
    ttr: IVDMetricTimes
  }
  volatility: IVDMetricTimes
}

interface ManagedFund {
  managed_fund: {
    slug: string;
  }
}

export interface IIFundManager {
  first_name: string;
  last_name: string;
  image_url: string;
  bio_url: string | null;
  title: string;
  subtitle: string;
  linkedin_url: string | null;
  managed_funds: ManagedFund[];
}
