import axios from "axios";
import { V1_BACKEND_URL } from "../lib/utils";
import { IPerformanceMetrics, IETFV1FactSheetData, INewsData, IFactsheetHeaderData, IDividendMetrics, ITimeSeriesData, IFactSheetMoreDetails, ITrailingReturnsData, IFactSheetHoldingsAndSectors, ISimilarEtfData, TiCardData, IETFFlowsPerformanceData, IDrawdownDataResponse, ChartData, IVolatilityDataResponse, IVolatilityDrawdownData, IFactSheetSectors, UserNotification, UserUnreadNotifications, IIFundManager } from "../types/customData";
import { IPlatformLimits } from "../types/sidebar";
import { IJsonSheet } from "json-as-xlsx";
import { IChatFeedbackSubmission } from "@/types/chat";

export async function validateInvitationCode(code: string): Promise<boolean> {
  try {
    const response = await axios.post(`${process.env.REACT_APP_V1_API_URL}/validate-invite`, {
      code,
    });
    return response.data.isValid;
  }
  catch (error) {
    console.error("Error validating invitation code: ", error);
    throw error;
  }
}

export const deleteUserAccount = async (
  userId: string | undefined,
  access_token: string,
): Promise<boolean> => {
  try {
    if (!userId) {
      throw new Error("User ID is required");
    }
    const response = await axios.delete(`${V1_BACKEND_URL}/accounts/user/${userId}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });

    if (response.status !== 200) {
      throw new Error("Failed to delete user account");
    }

    return response.data;
  } catch (error) {
    //TODO Handle error
    console.error("Error deleting user account: ", error);
    throw error;
  }
}

export async function getEtfPerformance(
    etf_ticker: string,
    access_token: string,
): Promise<IPerformanceMetrics> {
    try {
        const { data } = await axios.get<IPerformanceMetrics>(`${V1_BACKEND_URL}/reports/performance/${etf_ticker}`, {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });
        return data;
    } catch (error) {
        //TODO Handle error
        console.error("Error fetching chat by id: ", error);
        throw error;
    }
}

export async function getEtfV1Details(
    etf_ticker: string,
    access_token: string,
): Promise<IETFV1FactSheetData> {
    try {
        const { data } = await axios.get(`${V1_BACKEND_URL}/reports/factsheet/${etf_ticker}`, {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });
        return data;
    } catch (error) {
        //TODO Handle error
        console.error("Error fetching chat by id: ", error);
        throw error;
    }
}

export async function getEtfFlowPerformanceMetrics(
    etf_ticker: string,
    access_token: string,
): Promise<IETFFlowsPerformanceData> {
    try {
        const { data } = await axios.get<IETFFlowsPerformanceData>(`${V1_BACKEND_URL}/reports/factsheet/performance-flows/${etf_ticker}`, {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });
        return data;
    } catch (error) {
        //TODO Handle error
        console.error("Error fetching chat by id: ", error);
        throw error;
    }
}

export async function getEtfNews(
    etf_ticker: string,
    access_token: string,
): Promise<INewsData[]> {
    try {
        const { data } = await axios.get<INewsData[]>(`${V1_BACKEND_URL}/reports/news/${etf_ticker}`, {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });
        return data;
    } catch (error) {
        //TODO Handle error
        console.error("Error fetching chat by id: ", error);
        throw error;
    }
}

export async function getFactsheetHeader(
    etf_ticker: string,
    access_token: string,
): Promise<IFactsheetHeaderData> {
    try {
        const { data } = await axios.get<IFactsheetHeaderData>(`${V1_BACKEND_URL}/reports/factsheet/header/${etf_ticker}`, {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });
        return data;
    } catch (error) {
        //TODO Handle error
        console.error("Error fetching chat by id: ", error);
        throw error;
    }
}

interface IFactsheetDividendResponse {
    dividend_history: IDividendMetrics;
    dividend_only_data: ITimeSeriesData[];
    trailing_returns_data: ITrailingReturnsData
    monthly_returns: { date: string; net_return: number }[];
    last_updated: string;
}

export async function getFactsheetDividends(
    etf_ticker: string,
    access_token: string,
): Promise<IFactsheetDividendResponse> {
    try {
        const { data } = await axios.get<IFactsheetDividendResponse>(`${V1_BACKEND_URL}/reports/factsheet/dividends/${etf_ticker}`, {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });
        return data;
    } catch (error) {
        //TODO Handle error
        console.error("Error fetching chat by id: ", error);
        throw error;
    }
}

export async function getFactsheetMoreDetails(
    etf_ticker: string,
    access_token: string,
): Promise<IFactSheetMoreDetails> {
    try {
        const { data } = await axios.get<IFactSheetMoreDetails>(`${V1_BACKEND_URL}/reports/factsheet/more-details/${etf_ticker}`, {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });
        return data;
    } catch (error) {
        //TODO Handle error
        console.error("Error fetching chat by id: ", error);
        throw error;
    }
}


export async function getFactsheetDescription(
    etf_ticker: string,
    access_token: string,
): Promise<string> {
    try {
        const { data } = await axios.get<string>(`${V1_BACKEND_URL}/reports/factsheet/description/${etf_ticker}`, {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });
        return data;
    } catch (error) {
        //TODO Handle error
        console.error("Error fetching chat by id: ", error);
        throw error;
    }
}


export async function getFactsheetHoldingsAndSectors(
    etf_ticker: string,
    access_token: string,
): Promise<IFactSheetHoldingsAndSectors> {
    try {
        const { data } = await axios.get<IFactSheetHoldingsAndSectors>(`${V1_BACKEND_URL}/reports/factsheet/holdings-sectors/${etf_ticker}`, {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });
        return data;
    } catch (error) {
        //TODO Handle error
        console.error("Error fetching chat by id: ", error);
        throw error;
    }
}

export async function getFactsheetSectors(
    etf_ticker: string,
    access_token: string,
): Promise<IFactSheetSectors> {
    try {
        const { data } = await axios.get<IFactSheetSectors>(`${V1_BACKEND_URL}/reports/factsheet/sectors/${etf_ticker}`, {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });
        return data;
    } catch (error) {
        //TODO Handle error
        console.error("Error fetching chat by id: ", error);
        throw error;
    }
}

export async function fetchUserMessageConsumption(
    userId: string,
    access_token: string,
): Promise<number> {
    try {
        const { data } = await axios.get(
            `${V1_BACKEND_URL}/messages-consumption/${userId}`,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            },
        );
        return data.message_count;
    } catch (error) {
        console.error("Error fetching token usage: ", error);
        throw error;
    }
}

export async function fetchUserLimits(
    userId: string,
    access_token: string,
): Promise<IPlatformLimits> {
    try {
        const { data } = await axios.get(
            `${V1_BACKEND_URL}/platform-limits/${userId}`,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            },
        );
        return data.currentUserLimits
    } catch (error) {
        console.error("Error fetching token usage: ", error);
        throw error;
    }
}

export async function getSimilarEtfs(
    etf_ticker: string,
    access_token: string,
): Promise<ISimilarEtfData[] | null> {
    try {
        const { data } = await axios.get<ISimilarEtfData[] | null>(`${V1_BACKEND_URL}/reports/similar-etfs/${etf_ticker}`, {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });
        return data;
    } catch (error) {
        //TODO Handle error
        console.error("Error fetching chat by id: ", error);
        throw error;
    }
}

interface IEtfHoldingsResponse {
    excelData: IJsonSheet[],
    fileName: string
}

export async function getEtfHoldings(
    etf_ticker: string,
    access_token: string,
): Promise<IEtfHoldingsResponse | null> {
    try {
        const { data } = await axios.get<IEtfHoldingsResponse | null>(`${V1_BACKEND_URL}/reports/holdings/${etf_ticker}`, {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });
        return data;
    } catch (error) {
        //TODO Handle error
        console.error("Error fetching chat by id: ", error);
        throw error;
    }
}

export async function getTiCardData(
    etf_ticker: string,
    access_token: string,
): Promise<TiCardData> {
    try {
        const { data } = await axios.get<TiCardData>(`${V1_BACKEND_URL}/reports/factsheet/ti-card/${etf_ticker}`, {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });
        return data;
    } catch (error) {
        //TODO Handle error
        console.error("Error fetching chat by id: ", error);
        throw error;
    }
}

export async function getEtfDrawdownData(
    etf_ticker: string,
    access_token: string
): Promise<ChartData> {
    try {
        const fiveYearsAgoDate = new Date();
        fiveYearsAgoDate.setFullYear(fiveYearsAgoDate.getFullYear() - 5);
        const fiveYearsAgoDateString = fiveYearsAgoDate.toISOString().split("T")[0];
        const todayDate = new Date();
        const todayDateString = todayDate.toISOString().split("T")[0];

        const { data } = await axios.get<IDrawdownDataResponse>(`${V1_BACKEND_URL}/reports/drawdown/${etf_ticker}?from=${fiveYearsAgoDateString}&to=${todayDateString}`, {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });
        return data.drawdown
    } catch (error) {
        //TODO Handle error
        console.error("Error fetching chat by id: ", error);
        throw error;
    }
}

export async function getEtfVolatilityData(
    etf_ticker: string,
    access_token: string
): Promise<ChartData> {
    try {
        const fiveYearsAgoDate = new Date();
        fiveYearsAgoDate.setFullYear(fiveYearsAgoDate.getFullYear() - 5);
        const fiveYearsAgoDateString = fiveYearsAgoDate.toISOString().split("T")[0];
        const todayDate = new Date();
        const todayDateString = todayDate.toISOString().split("T")[0];

        const { data } = await axios.get<IVolatilityDataResponse>(`${V1_BACKEND_URL}/reports/volatility/${etf_ticker}?from=${fiveYearsAgoDateString}&to=${todayDateString}`, {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });
        return data.volatility
    } catch (error) {
        //TODO Handle error
        console.error("Error fetching chat by id: ", error);
        throw error;
    }
}

export async function getEtfVolatilityDrawdownData(
    etf_ticker: string,
    access_token: string
): Promise<IVolatilityDrawdownData> {
    try {
        const { data } = await axios.get<IVolatilityDrawdownData>(`${V1_BACKEND_URL}/reports/volatility-drawdown/${etf_ticker}`, {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });
        return data
    } catch (error) {
        //TODO Handle error
        console.error("Error fetching chat by id: ", error);
        throw error;
    }
}

export async function sendChatFeedback(
  chatId: string,
  feedback: IChatFeedbackSubmission,
  access_token: string,
): Promise<void> {
  try {
    await axios.post(
      `${V1_BACKEND_URL}/chat/${chatId}/feedback`,
      feedback,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      },
    );
  } catch (error) {
    console.error("Error sending feedback: ", error);
    throw error;
  }
}

export async function getUserNotifications(
  access_token: string,
  userId: string,
): Promise<UserNotification[]> {
  try {
    const { data } = await axios.get<UserNotification[]>(
      `${V1_BACKEND_URL}/notifications/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      },
    );
    return data;
  } catch (error) {
    console.error("Error fetching notifications: ", error);
    throw error;
  }
}

export async function getUserUnreadNotifications(
  access_token: string,
  userId: string,
): Promise<UserUnreadNotifications[]> {
  try {
    const { data } = await axios.get<UserUnreadNotifications[]>(
      `${V1_BACKEND_URL}/notifications/${userId}/unread`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      },
    );
    return data;
  } catch (error) {
    console.error("Error fetching notifications: ", error);
    throw error;
  }
}


export async function markUserNotificationAsRead(
  access_token: string,
  userId: string,
  notificationIds: string[],
): Promise<void> {
  try {
    await axios.put(
      `${V1_BACKEND_URL}/notifications/${userId}/mark-read`,
      { notificationIds },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      },
    )
  } catch (error) {
    console.error("Error marking notification as read: ", error);
    throw error;
  }
}

export async function getEtfManagers(
    etf_ticker: string,
    access_token: string
): Promise<IIFundManager[]> {
    try {
        const { data } = await axios.get<IIFundManager[]>(`${V1_BACKEND_URL}/reports/fund-managers/${etf_ticker}`, {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });
        return data
    } catch (error) {
        //TODO Handle error
        console.error("Error fetching chat by id: ", error);
        throw error;
    }
}
