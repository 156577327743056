import {
  differenceInDays,
  differenceInMonths,
  format,
  isFuture,
  isSameMonth,
} from "date-fns";
import { IconClose, InfoIcon, LoadingCircle } from "../ui/icons";
import { PopoverClose } from "../ui/popover";
import { AnimatePresence, motion } from "framer-motion";
import { useRef, useState } from "react";
import { cn } from "@/lib/utils";
import { Calendar } from "@/components/ui/calendar";
import { Card, CardContent } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  PrismicImage,
  PrismicRichText,
  usePrismicDocumentsByType,
} from "@prismicio/react";
import { PrismicDocument, Query, RichTextField } from "@prismicio/client/*";
import {
  Calendar1,
  Cast,
  ChevronLeft,
  ChevronRight,
  Clock,
  ExternalLink,
  GraduationCap,
  Landmark,
  MapPin,
} from "lucide-react";
import {
  DayProps,
  Modifiers,
  useDayPicker,
  useDayRender,
} from "react-day-picker";
import { ensureAbsoluteUrl } from "./helpers";
import GenericEventLogo from "../../assets/generic-event.png";

const Events = () => {
  const [view, setView] = useState<"feed" | "settings">("feed");

  return (
    <div className="">
      <div className="flex items-center justify-between px-3 py-4">
        <h1 className="pl-2 text-xl font-bold">
          {view === "feed" ? "Events" : "Settings"}
        </h1>
        <div className="flex items-center space-x-2">
          {/* <Settings
            className="hover:cursor-pointer"
            onClick={() =>
              setView((prev) => (prev === "feed" ? "settings" : "feed"))
            }
            size={24}
          /> */}
          <PopoverClose className="PopoverClose" aria-label="Close">
            <IconClose className="h-6 w-6" />
          </PopoverClose>
        </div>
      </div>
      <div className="p-3">
        <WidgetContent view={view} />
      </div>
    </div>
  );
};

export default Events;

const MainContent = () => {
  const [view, setView] = useState<"calendar" | "upcoming">("calendar");
  return (
    <Tabs defaultValue={view} className="w-full">
      <TabsList className="grid w-full grid-cols-2">
        <TabsTrigger
          value="calendar"
          className={cn(
            "text-center",
            view === "calendar"
              ? "data-[state=active]:text-blue-ice"
              : "text-white",
          )}
          onClick={() => setView("calendar")}
        >
          Calendar
        </TabsTrigger>
        <TabsTrigger
          value="upcoming"
          className={cn(
            "text-center",
            view === "upcoming"
              ? "data-[state=active]:text-blue-ice"
              : "text-white",
          )}
          onClick={() => setView("upcoming")}
        >
          Upcoming Events
        </TabsTrigger>
      </TabsList>
      <TabsContent value="calendar">
        <EventsCalendar />
      </TabsContent>
      <TabsContent value="upcoming">
        <UpcomingEvents />
      </TabsContent>
    </Tabs>
  );
};

interface Event {
  title: string;
  description: RichTextField;
  date: Date;
  start_date: Date;
  end_date?: Date;
  location: string;
  eventPage: string;
  time: string;
  type: "webinar" | "workshop" | "seminar";
  event_logo: any;
  organizer: string;
  ce_credits: number;
}

const extractEvents = (
  events:
    | Query<PrismicDocument<Record<string, any>, string, string>>
    | undefined,
  includeMultiDayEvents = false,
): Event[] => {
  if (!events) return [];

  const multiDayEvents: Event[] = [];

  return events.results
    .map((event) => {
      const data = event.data;
      const eventObject: Event = {
        title: data.title,
        description: data.description,
        date: new Date(data.date),
        start_date: new Date(data.date),
        end_date: data.end_date ? new Date(data.end_date) : undefined,
        time: new Date(data.date).toLocaleTimeString(),
        location: data.location,
        eventPage: data.event_page,
        type: data.type,
        event_logo: data.event_logo,
        organizer: data.organizer,
        ce_credits: data.ce_credits,
      };

      const hasEndDate = data.end_date ? true : false;
      if (hasEndDate && includeMultiDayEvents) {
        // get all dates between start (exlusive) and end date (inclusive)
        // and create a new event object for each date
        // with the same data as the original event

        const startDate = new Date(data.date);
        const endDate = new Date(data.end_date);
        const dateDiff = differenceInDays(endDate, startDate) + 1;

        for (let i = 1; i <= dateDiff; i++) {
          const newDate = new Date(startDate);
          newDate.setDate(startDate.getDate() + i);
          multiDayEvents.push({
            ...eventObject,
            date: newDate,
          });
        }
      }

      return eventObject;
    })
    .concat(multiDayEvents);
};

const UpcomingEvents = () => {
  const [eventDocs, { state }] = usePrismicDocumentsByType("events");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showArrows, setShowArrows] = useState(false);

  if (state === "loading" || state === "idle") {
    return <LoadingCircle />;
  }

  const events = extractEvents(eventDocs);
  const now = new Date();

  const upcomingEvents = events
    .filter((event) => {
      const eventDate = event.date;
      return (
        differenceInMonths(eventDate, now) <= 1 &&
        differenceInDays(eventDate, now) >= 0 &&
        isFuture(eventDate)
      );
    })
    .sort((a, b) => a.date.getTime() - b.date.getTime());

  if (upcomingEvents.length === 0) {
    return (
      <div className="text-center text-gray-500">
        <p>No upcoming events</p>
      </div>
    );
  }

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? upcomingEvents.length - 1 : prevIndex - 1,
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === upcomingEvents.length - 1 ? 0 : prevIndex + 1,
    );
  };

  const currentEvent = upcomingEvents[currentIndex];

  const showLeftArrow = upcomingEvents.length > 1 && currentIndex !== 0;
  const showRightArrow =
    upcomingEvents.length > 1 && currentIndex !== upcomingEvents.length - 1;

  const atLeastOneArrow = showLeftArrow || showRightArrow;
  const hasEndDate = currentEvent.end_date;

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <div className="flex-1">
          <Card className="overflow-hidden">
            <CardContent className="p-4">
              <div className="flex gap-4">
                <div className="flex-1">
                  <div
                    className="relative mb-2 grid grid-cols-12"
                    onMouseEnter={() => setShowArrows(true)}
                    onMouseLeave={() => setShowArrows(false)}
                  >
                    {showLeftArrow && (
                      <ChevronLeft
                        className="rdp-button_reset rdp-button absolute left-0 inline-flex h-8 w-8 justify-center rounded-sm border border-input bg-transparent px-0 py-0 text-sm text-black opacity-50 hover:bg-accent hover:text-accent-foreground hover:opacity-100 focus:outline-none"
                        onClick={handlePrev}
                      />
                    )}

                    {showRightArrow && (
                      <ChevronRight
                        className="rdp-button_reset rdp-button absolute right-0 inline-flex h-8 w-8 justify-center rounded-sm border border-input bg-transparent px-0 py-0 text-sm text-black opacity-50 hover:bg-accent hover:text-accent-foreground hover:opacity-100 focus:outline-none"
                        onClick={handleNext}
                      />
                    )}
                  </div>

                  <div
                    className={cn(
                      "flex items-start gap-4",
                      `${atLeastOneArrow ? "pt-10" : ""}`,
                    )}
                    style={{
                      gridColumnStart: showLeftArrow && showArrows ? 2 : 1,
                      gridColumnEnd: showLeftArrow && showArrows ? 11 : 12,
                    }}
                  >
                    {currentEvent.event_logo?.url ? (
                      <PrismicImage
                        field={currentEvent.event_logo}
                        imgixParams={{ w: 45, h: 45 }}
                        className="pt-1"
                      />
                    ) : (
                      <img
                        src={GenericEventLogo}
                        alt="Event Logo"
                        className="h-8 w-8 pt-1"
                      />
                    )}
                    <h3 className="text-lg font-semibold">
                      {currentEvent.title}
                    </h3>
                  </div>

                  <div className="mt-6 grid grid-cols-2 gap-4 text-sm text-gray-600">
                    {currentEvent.organizer && (
                      <div className="flex items-center gap-2">
                        <Landmark className="h-4 w-4" />
                        <span>{currentEvent.organizer}</span>
                      </div>
                    )}

                    {currentEvent.type && (
                      <div className="flex items-center gap-2">
                        <Cast className="h-4 w-4" />
                        <span>{currentEvent.type}</span>
                      </div>
                    )}

                    {currentEvent.end_date && (
                      // <div className="col-span-2 flex items-center gap-2">
                      <div className="flex items-center gap-2">
                        <Calendar1 className="h-4 w-4" />
                        <span>
                          {isSameMonth(
                            currentEvent.date,
                            currentEvent.end_date,
                          ) ? (
                            <>
                              {format(currentEvent.date, "MMMM d")} -{" "}
                              {format(currentEvent.end_date, "d, yyyy")}
                            </>
                          ) : (
                            <>
                              {format(currentEvent.date, "MMMM d")} -{" "}
                              {format(currentEvent.end_date, "MMMM d")}
                            </>
                          )}
                        </span>
                      </div>
                    )}

                    {currentEvent.date && !hasEndDate && (
                      <div className="flex items-center gap-2">
                        <Calendar1 className="h-4 w-4" />
                        <span>{format(currentEvent.date, "MMMM d, yyyy")}</span>
                      </div>
                    )}

                    {currentEvent.date && !hasEndDate && (
                      <div className="flex items-center gap-2">
                        <Clock className="h-4 w-4" />
                        <span>{format(currentEvent.date, "h:mm a z")}</span>
                      </div>
                    )}

                    {currentEvent.ce_credits ? (
                      <div className="flex items-center gap-2">
                        <GraduationCap className="h-4 w-4" />
                        <span>
                          {currentEvent.ce_credits}{" "}
                          {currentEvent.ce_credits > 1
                            ? "CE credits"
                            : "CE credit"}
                        </span>
                      </div>
                    ) : null}

                    {currentEvent.eventPage && (
                      <div className="flex items-center gap-2">
                        <ExternalLink className="h-4 w-4" />
                        <a
                          href={currentEvent.eventPage}
                          className="text-blue-600 hover:underline"
                        >
                          Event page
                        </a>
                      </div>
                    )}

                    {currentEvent.location && (
                      <div className="flex items-center gap-2">
                        <MapPin className="h-4 w-4" />
                        <span>{currentEvent.location}</span>
                      </div>
                    )}
                  </div>

                  {currentEvent.description && (
                    <div className="mt-4 grid grid-cols-10">
                      <InfoIcon className="col-span-1 mt-4 h-8 w-4 text-gray-600" />
                      <div className="col-span-9 mt-3 text-sm text-gray-700">
                        <PrismicRichText field={currentEvent.description} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

const EventsCalendar = () => {
  const [date, setDate] = useState<Date | undefined>(new Date());

  const [eventDocs, { state }] = usePrismicDocumentsByType("events");

  const handleMonthChange = (month: Date) => {
    setDate(undefined);
  };

  if (state === "loading" || state === "idle") {
    return <LoadingCircle />;
  }

  const extractEventDates = (events: Event[]) => {
    return events.map((event) => event.date);
  };

  const events = extractEvents(eventDocs, true);
  const eventDates = extractEventDates(events);
  const eventDatesFormatted = eventDates.map((date) =>
    format(date, "yyyy-MM-dd"),
  );
  const filterEventsByDate = (events: Event[], date: Date) => {
    return events.filter((event) => {
      const eventDate = new Date(event.date);
      return (
        eventDate.getFullYear() === date.getFullYear() &&
        eventDate.getMonth() === date.getMonth() &&
        eventDate.getDate() === date.getDate()
      );
    });
  };

  return (
    <Calendar
      mode="single"
      selected={date}
      onSelect={setDate}
      onMonthChange={handleMonthChange}
      className="rounded-md border"
      modifiers={{
        eventDate: eventDates,
      }}
      components={{
        Day: CustomDayCell,
      }}
      modifiersClassNames={{
        eventDate: "border-b-4 !border-red-500 border-solid",
      }}
      footer={
        date && eventDatesFormatted.includes(format(date, "yyyy-MM-dd")) ? (
          <EventList events={filterEventsByDate(events, date)} />
        ) : null
      }
    />
  );
};

const CustomDayCell = ({ date, displayMonth }: DayProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { isButton, isHidden, buttonProps, divProps, activeModifiers } =
    useDayRender(date, displayMonth, buttonRef);

  let isModifierMatch = activeModifiers.eventDate;

  if (isHidden) {
    return <></>;
  }

  if (isButton) {
    return (
      <button
        {...buttonProps}
        ref={buttonRef}
        className={cn({
          selected: buttonProps["aria-selected"],
          "text-gray-400": activeModifiers.outside,
          "rounded-sm bg-blue-lightIce": isModifierMatch,
          "h-full w-full": true,
        })}
      >
        {date.getDate()}
      </button>
    );
  }

  return (
    <div
      {...divProps}
      className="day-outside text-muted-foreground aria-selected:bg-accent/50 aria-selected:text-muted-foreground"
    >
      {date.getDate()}
    </div>
  );
};
const EventList = ({ events }: { events: Event[] }) => {
  return (
    <div className="mt-2">
      <Card>
        <CardContent className="space-y-1 p-3">
          {events.map((event) => (
            <EventRow key={event.title} event={event} />
          ))}
        </CardContent>
      </Card>
    </div>
  );
};

const EventRow = ({ event }: { event: Event }) => {
  const hasLink = event.eventPage ? true : false;
  const isMultiDay = event.end_date ? true : false;
  let dayPosition = 0;

  if (isMultiDay) {
    const diff = differenceInDays(event.date, event.start_date);
    dayPosition = diff + 1;
  }

  let link = "";

  if (hasLink) {
    link = ensureAbsoluteUrl(event.eventPage);
  }

  const content = (
    <div className="rounded-md p-1 hover:bg-gray-100">
      <div className="flex flex-row items-center gap-2 text-sm font-semibold">
        {event.event_logo?.url ? (
          <PrismicImage
            field={event.event_logo}
            imgixParams={{ w: 20, h: 20 }}
            className="pt-1"
          />
        ) : (
          <img
            src={GenericEventLogo}
            alt="Event Logo"
            className="h-8 w-8 pt-1"
          />
        )}
        {event.title} {isMultiDay ? `- Day ${dayPosition}` : null}
      </div>
      <div className="mt-1 grid grid-cols-2">
        <p className="flex items-center gap-2 text-xs font-light text-gray-500">
          {isMultiDay ? (
            <Calendar1 className="h-4 w-4" />
          ) : (
            <Clock className="h-4 w-4" />
          )}

          {!event.end_date ? (
            <span>{format(event.date, "h:mm a z")}</span>
          ) : (
            <span>
              {isSameMonth(event.start_date, event.end_date) ? (
                <>
                  {format(event.start_date, "MMMM d")} -{" "}
                  {format(event.end_date, "d, yyyy")}
                </>
              ) : (
                <>
                  {format(event.start_date, "MMMM d")} -{" "}
                  {format(event.end_date, "MMMM d")}
                </>
              )}
            </span>
          )}
        </p>
        {event.organizer && (
          <p className="flex items-center gap-2 py-2 text-xs font-light text-gray-500">
            <Landmark className="h-4 w-4" />
            <span>{event.organizer}</span>
          </p>
        )}
        {event.type && (
          <p className="flex items-center gap-2 py-2 text-xs font-light text-gray-500">
            <Cast className="h-4 w-4" />
            <span>{event.type}</span>
          </p>
        )}
      </div>
    </div>
  );

  return hasLink ? <a href={link}>{content}</a> : content;
};

const WidgetContent = ({ view }: { view: "feed" | "settings" }) => {
  return (
    <>
      <AnimatePresence mode="wait">
        {view === "feed" ? (
          <motion.div
            key="feed"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 20 }}
            transition={{ duration: 0.2 }}
          >
            <MainContent />
          </motion.div>
        ) : view === "settings" ? (
          <motion.div
            key="settings"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 20 }}
            transition={{ duration: 0.2 }}
          >
            <div> Settings in Progress.. </div>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </>
  );
};
