import { useGlobalState } from "@/context/GlobalState";
import {
  getUserNotifications,
  markUserNotificationAsRead,
} from "@/requests/v1";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

export const useNotifications = () => {
  const {
    session: { access_token, user },
  } = useGlobalState();

  const { isPending, isError, error, data } = useQuery({
    queryKey: ["notifications"],
    queryFn: async () => {
      const data = await getUserNotifications(access_token, user.id);
      return data;
    },
  });

  const { mutate: markAsRead, isSuccess } = useMutation({
    mutationFn: async () => {
      if (!data) return;
      const result = await markUserNotificationAsRead(
        access_token,
        user.id,
        data.flatMap((item) => item.id),
      );
      return result;
    },
  });

  useEffect(() => {
    if (data) {
      markAsRead();
    }
  }, [data]);

  return { isPending, isError, error, data };
};
